<template>
  <AdminReportingForm
    title="Park Visitation by Residency/Discount Class"
    description="The Park Visitation by Residency/Discount Class is at the park level and
      provides reservation figures broken down by residency and discount class
      for the date range specified. This report can be run for any 365 day date range."
    file="ParkVisitationByResidencyDiscountClass$"
    :reportStatus="reportStatus"
    :submitting="submitting"
    :allowGlobal="true"
    @getReport="getReport"
  />
</template>

<script>
import AdminReportingForm from "@/components/admin/AdminReportingForm.vue";
import AdminReportingService from "@/services/admin/AdminReportingService.js";
import moment from "moment";

export default {
  name: "ParkVisitationByResidencyReport",
  title: "Admin - Park Visitation by Residency/Discount Class Report",
  data() {
    return {
      reportStatus: "",
      submitting: false
    };
  },
  components: {
    AdminReportingForm
  },
  computed: {
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    }
  },
  methods: {
    getReport(reportData) {
      this.reportStatus = "";
      this.submitting = true;
      const date = moment();
      reportData.dateRun = date.format("MM/DD/YYYY hh:mm:ss A");

      const service = new AdminReportingService(this.tenantId);
      service
        .getVisitationReport(reportData)
        .then(response => {
          if (response.deliveryMethod == "Download") {
            const filename = reportData.filename.replace(
              "$",
              date.format("MM_DD_YYYY_hh_mm_ss_A")
            );
            service.forceFileDownload(response, filename);
          } else {
            this.reportStatus = "Report sent!";
          }
        })
        .finally(() => {
          this.submitting = false;
        });
    }
  }
};
</script>
